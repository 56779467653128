<template>
    <div class="c-customers-groups">
        <v-title title="Customer Groups"></v-title>
        <template v-if="hasPermission('listCustomersGroups')">
            <title-box>
                <div class="row">
                    <div class="col-9">
                        <h1>Customer Groups</h1>
                    </div>
                    <div class="col-3 text-right">
                        <mercur-button v-if="hasPermission('MerchantCentral/createCustomersGroups')" :to="{ name: 'AddCustomersGroup' }" class="btn btn-primary text-uppercase">
                            <i class="fas fa-plus"></i> Add new customer group
                        </mercur-button>
                    </div>
                </div>
            </title-box>
            <div class="container-fluid mt-3">
                <pagination :pagination="pagination" @change="changeAmountOfItems"></pagination>
                <merchant-data-table class="shadow" :options="options" :url="url" ref="table" @paginationSet="updatePagination" :isNotApplicationBound="true"></merchant-data-table>
            </div>
        </template>
        <template v-else>
            <div class="mt-3">
                Not allowed to see this view
            </div>
        </template>

        <mercur-dialog :is-open.sync="dialog.isOpen">
            <div class="mt-3 mb-1">{{ dialog.content }}</div>
            <template #footer>
                <div class="text-right">
                    <mercur-button class="btn" @click.native="dialog.isOpen = false">Cancel</mercur-button>
                    <mercur-button class="btn btn-primary" @click.native="revoke" :disabled="isLoading">Confirm</mercur-button>
                </div>
            </template>
        </mercur-dialog>

    </div>
</template>

<script>
import CONFIG from '@root/config'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Pagination from '@/components/elements/table/Pagination'

export default {
    name: 'CustomersGroups',
    components: { MerchantDataTable, Pagination },
    data () {
        return {
            url: CONFIG.API.ROUTES.CUSTOMERS.GROUPS.OVERVIEW,
            isDialogOpen: false,
            activeData: null,
            pagination: {},
            amountOfDisplayedItems: 50,
            isLoading: false,
            dialog: {
                isOpen: false,
            },
            options: {
                columns: {
                    'Customer Group Name': {
                        field: 'groupName',
                        link: (value, data) => {
                            return {
                                name: 'EditCustomersGroup',
                                params: {
                                    customersGroupId: data.groupId,
                                },
                            }
                        },
                    },
                    'Date Created': { field: 'dateCreated' },
                },
                actions: {
                    type: 'dropdown',
                    items: [
                        {
                            text: 'Edit',
                            to: ({ data }) => {
                                return {
                                    name: 'EditCustomersGroup',
                                    params: {
                                        customersGroupId: data.groupId,
                                    },
                                }
                            },
                            isHidden: () => {
                                return !this.hasPermission('updateCustomersGroups')
                            },
                        },
                        {
                            text: 'Revoke',
                            onClick: ({ data }) => {
                                this.triggerRevokeDialog(data)
                            },
                            isDisabled: () => {
                                return this.isLoading
                            },
                            isHidden: () => {
                                return !this.hasPermission('revokeCustomersGroups')
                            },
                        },
                    ],
                },
                paginationType: 'custom',
                paginationPageSize: 50,
                cacheBlockSize: 50,
                defaultColDef: { menuTabs: [] },
            },
        }
    },
    methods: {
        triggerRevokeDialog (data) {
            this.activeData = data
            this.dialog = {
                isOpen: true,
                content: `Are you sure that you want to revoke this customers group?`,
            }
        },
        revoke () {
            const url = CONFIG.API.ROUTES.CUSTOMERS.GROUPS.REVOKE
            this.isLoading = true
            this.post(url, { id: this.activeData.groupId }, 'Revoke success').then(() => {
                this.$refs.table.refreshGrid()
                this.dialog = {
                    isOpen: false,
                }
            })
        },
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-customers-groups {
        &__table {
            margin-top: 50px;
        }
    }
</style>
